import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import YouTube from 'react-youtube';


import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'

import { injectIntl, useIntl } from "gatsby-plugin-intl"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const AboutPage = () => {
  const intl = useIntl()
  const opts = {
    playerVars: {
      controls: 0,
      rel: 0,
      showinfo: 0,
      fs: 0,
      cc_load_policy: 0,
      modestbranding: 1,
      autohide: 1,
      frameborder: 0,
      disablekb: 1,
      mode: 'opaque'
    }
  }
  const data = useStaticQuery(
    graphql`
    query {
      allContentfulAboutPage {
        nodes {
          id
          section
          title
          node_locale
          seoTitle
          seoDescription {
            seoDescription
          }
          childContentfulAboutPageAboutPageContentRichTextNode {
            json
          }
        }
      }
    }`)
  return (
    <Layout >
      <SEO
        title={intl.formatMessage({ id: "aboutPage_seo_title" })}
        description={intl.formatMessage({ id: "aboutPage_seo_description" })}
      />
      <article className="aboutPageContainer">
        <section className="aboutRow">
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'about').map(artl => {

              return (
                <section key={artl.id}>
                  <h1>{artl.title.replace('Batrade', '')} <span className="batrade">Batrade</span></h1>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </section >
              )
            })
          }
          <figure onContextMenu={(e) => e.preventDefault()} role='presentation'>
            <YouTube videoId="T77jJ80puGw" opts={opts} />
          </figure>
        </section>
        <section className="aboutRow">
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'vision').map(artl => {

              return (
                <section key={artl.id}>
                  <h2>{artl.title}</h2>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </section >
              )
            })
          }
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'team').map(artl => {

              return (
                <section key={artl.id}>
                  <h2>{artl.title}</h2>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </section >
              )
            })
          }
        </section>
        <section className="aboutRowfull">
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'environment').map(artl => {

              return (
                <span key={artl.id}>
                  <h2 >{artl.title}</h2>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </span>
              )
            })
          }
        </section>
        <section className="aboutRow">
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'goals').map(artl => {

              return (
                <section key={artl.id}>
                  <h2>{artl.title}</h2>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </section >
              )
            })
          }
          {
            data.allContentfulAboutPage.nodes.filter(artl => artl.node_locale === intl.locale && artl.section === 'price').map(artl => {

              return (
                <section key={artl.id}>
                  <h2>{artl.title}</h2>
                  {documentToReactComponents(artl.childContentfulAboutPageAboutPageContentRichTextNode.json)}
                </section >
              )
            })
          }
        </section>
      </article>
    </Layout >
  )
}

export default injectIntl(AboutPage)
